<div [dir]="invertDirection ? 'rtl' : 'ltr'" fxLayout="row wrap" fxLayoutAlign="space-between start" class="px-20">

  <div fxFlex.xs="100" fxFlex="45" fxFlex.gt-lg="500px">
    <h1>Unsere<br>Gardinen</h1>
    <h2>Sichschutz & Blickfang zugleich</h2>
    <p>
      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae
      ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
      aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
    </p>
  </div>

  <div fxFlex.xs="100" fxFlex="45" fxFlex.gt-lg="500px">
    <div class="image-box">
      <img class="image-item" src="../../../../../assets/media/header-gardine_01.jpg" alt="service-item-image">
    </div>
  </div>

</div>
