<div class="kaan-footer px-20">

  <div class="kaan-container px-20" fxLayout fxLayoutAlign="space-between start">

    <div fxFlex="25">
      <p><b>Unternehmen</b></p>
      <a>
        KAAN Home Collection<br>
        Bleichstraße 35<br>
        65197 Wiesbaden
      </a>
    </div>

    <div fxFlex="25">
      <p><b>Bereiche</b></p>

      <a routerLink="/">Home</a><br>
      <a routerLink="/" fragment="ueber">Über uns</a><br>
      <a routerLink="/" fragment="kollektionen">Kollektionen</a><br>
      <a routerLink="/" fragment="kontakt">Kontakt</a>
    </div>

    <div fxFlex="25">
      <p><b>Rechtliches</b></p>

      <a routerLink="/impressum">Impressum</a><br>
      <a routerLink="/datenschutz">Datenschutz</a><br>
      <a routerLink="/cookies">Cookies</a>
    </div>

    <div fxFlex="25">
      <p><b>Soziale Medien</b></p>

      <a>Facebook</a>
      <a>Instagram</a>
    </div>
  </div>

  <div class="kaan-footer-copyright">
    <p>
      © {{dateYear}} KAAN Home Collection
    </p>
    <p>
      Made with &nbsp;<img src="../../../assets/icons/heart.svg">&nbsp; by
      <a href="https://www.exportarts.io" target="_blank" rel="noopener" style="display: inline;">
        exportarts.io
      </a>
    </p>
  </div>

</div>
