import { Component, Input, OnInit } from '@angular/core';
import { Collection } from '../../../../services/collection.service';

@Component({
  selector: 'kaan-collection-item',
  templateUrl: './collection-item.component.html',
  styleUrls: ['./collection-item.component.scss']
})
export class CollectionItemComponent implements OnInit {

  @Input() collection: Collection;

  constructor() {
  }

  ngOnInit() {
  }

}
