<kaan-page-header [title]="selectedCollection?.title" [image]="selectedCollection?.thumbnail"></kaan-page-header>

<div class="kaan-container px-50">

  <h2 [innerHTML]="selectedCollection?.subtitle">
  </h2>

  <p [innerHTML]="selectedCollection?.description">
  </p>

  <div class="px-20">

    <div class="kaan-gallery" fxLayout="row wrap" fxLayoutAlign="start start">

      <div *ngFor="let image of selectedCollection?.images; index as i"
           [fxFlex.xs]="100" [fxFlex.sm]="100/2" [fxFlex.md]="100/3" [fxFlex]="100/4">
        <div class="img-container" (click)="openLightBox(i)">
          <img src="{{image.thumb}}">
        </div>
      </div>

    </div>

  </div>

  <div class="pt-50">
    <a mat-stroked-button routerLink="/" fragment="kollektionen">Zu den Kollektionen</a>
  </div>

</div>
