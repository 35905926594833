<div class="kaan-container">
  <h1>Sie möchten<br>mehr erfahren?</h1>
  <h2>Erreichen Sie uns auf dem Kanal Ihrer Wahl</h2>
  <p>
    Bei KAAN Home Collection sind wir stets bestrebt Ihre Fragen zur beantworten und Ihre Wünsche zu erfüllen
  </p>

  <div fxLayout="row wrap" class="px-50 kaan-contact-items">

    <div fxFlex="100" fxFlex.sm="50" fxFlex.gt-sm="0 1 300px" *ngFor="let service of services">
      <kaan-contact-item [service]="service"></kaan-contact-item>
    </div>

  </div>

  <h2>...oder besuchen Sie uns</h2>
  <p>
    <b>KAAN Home Collection</b><br>
    Bleichstraße 35<br>
    65197 Wiesbaden
  </p>

  <div fxLayout fxLayoutGap="20px">
    <p>
      <b>Öffnungszeiten</b><br>
      Montag - Freitag<br>
      Samstag
    </p>
    <p>
      <br>
      10:00 - 18:00<br>
      10:00 - 16:00
    </p>
  </div>

</div>

<div class="kaan-map">
  <agm-map [latitude]="mapCenter.lat"
           [longitude]="mapCenter.lng"
           [scrollwheel]="false"
           [zoom]="14"
           [streetViewControl]="false"
           [styles]="mapStyles">

    <agm-marker [latitude]="marker.lat" [longitude]="marker.lng" [iconUrl]="icon"></agm-marker>
  </agm-map>
</div>
