<div #container class="wl-mobile-menu-button-container">

  <div fxLayout="column" fxLayoutAlign="space-between">
    <div class="line line-1"></div>
    <div class="line line-2"></div>
    <div class="line line-3"></div>
  </div>

  <div>
    <div class="line line-4"></div>
    <div class="line line-5"></div>
  </div>

</div>
