
<a routerLink="/kollektionen/{{collection?.id}}" class="image-box">
  <img class="image-item" [src]="collection?.thumbnail" alt="service-item-image">

  <div class="image-overlay" fxLayout fxLayoutAlign="start center">
    <h2>
      {{collection?.title}}
    </h2>
  </div>
</a>
