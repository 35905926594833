import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'kaan-mobile-menu-button',
  templateUrl: './mobile-menu-button.component.html',
  styleUrls: ['./mobile-menu-button.component.scss']
})
export class MobileMenuButtonComponent implements OnInit {

  @ViewChild('container', { static: true }) container: ElementRef;

  constructor() {
  }

  ngOnInit() {
  }

  open() {
    this.container.nativeElement.classList.add('open');
  }

  close() {
    this.container.nativeElement.classList.remove('open');
  }
}
