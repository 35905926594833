<div class="kaan-header kaan-container" fxLayout fxLayoutAlign="space-between center" fxLayoutAlign.xs="center center">

  <div fxFlex="0 1 300px" fxFlex.sm="50" fxFlex.gt-sm="45" fxFlex.gt-lg="500px">
    <img class="kaan-logo" src="../../../../assets/logo.svg" alt="logo kaan home collection">
    <p>
      Lorem ipsum dolor sit amet, consectetuer
      adipiscing elit, sed diam nonummy nibh
      euismod tincidunt ut laoreet dolore.
    </p>
  </div>

  <div fxHide.xs="true" fxFlex="45" fxFlex.gt-lg="500px" fxLayout fxLayoutAlign="end center">
    <div class="image-box">
      <div class="image-item"></div>
    </div>
  </div>

  <div class="kaan-arrow">
    <button mat-fab color="white" routerLink="/" fragment="ueber">
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
  </div>

</div>
