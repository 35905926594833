import { ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, Scroll } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'kaan-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

  anchor: string;

  constructor(private title: Title, router: Router, private viewportScroller: ViewportScroller) {

    // When routing from other component, the scroll position seems not be accurate (maybe site still loading)
    // therefore ngAfterViewInit + additional scrolling
    router.events.pipe(
      filter(e => e instanceof Scroll)
    ).subscribe((e: Scroll) => {
      this.anchor = e.anchor;
    });

  }

  ngOnInit() {
    // this.title.setTitle(this.metaTitle);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.viewportScroller.scrollToAnchor(this.anchor);
    }, 5);
  }

}
