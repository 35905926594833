<div class="kaan-navbar kaan-container" fxLayout fxLayoutAlign="end center" fxLayoutAlign.gt-sm="center center">
  <ng-container [ngTemplateOutlet]="navbarMenus"></ng-container>

  <div fxHide.gt-sm>
    <kaan-mobile-menu-button #mobileButton (click)="toggleMenu()"></kaan-mobile-menu-button>
  </div>
</div>

<!--<div #fixedNavbar class="kaan-fixed-navbar kaan-container" fxLayout fxLayoutAlign="space-between center">-->
<div #fixedNavbar class="kaan-fixed-navbar kaan-container" fxLayout fxLayoutAlign="space-between center">
  <a routerLink="/"><img src="../../../assets/logo_small.svg"></a>
  <ng-container [ngTemplateOutlet]="navbarMenus"></ng-container>

  <div fxHide.gt-sm>
    <kaan-mobile-menu-button #mobileButton2 (click)="toggleMenu()"></kaan-mobile-menu-button>
  </div>
</div>

<div #passingPoint class="navbar-passing-point"></div>

<ng-template #navbarMenus>
  <ul fxHide.lt-md>
    <li><a routerLink="/" fragment="ueber">Über uns</a></li>
    <li><a routerLink="/" fragment="kollektionen">Kollektionen</a></li>
    <li><a routerLink="/" fragment="kontakt">Kontakt</a></li>
  </ul>
</ng-template>


<!-- TODO -->
<div kaanScrollLock class="kaan-mobile-menu" *ngIf="menuOpen" fxHide.gt-sm>
  <div fxLayout fxLayoutAlign="center center">
    <ul class="mobile-list" (click)="closeMenu()">
      <li><a routerLink="/" fragment="ueber">Über uns</a></li>
      <li><a routerLink="/" fragment="kollektionen">Kollektionen</a></li>
      <li><a routerLink="/" fragment="kontakt">Kontakt</a></li>
      <li><hr></li>
      <li><a routerLink="/">Facebook</a></li>
      <li><a routerLink="/">Instagram</a></li>
    </ul>
  </div>
</div>
