<div>
  <kaan-header></kaan-header>
</div>

<div id="ueber" class="px-50 kaan-services">
  <kaan-services></kaan-services>
</div>

<div id="kollektionen" class="px-50 bg-light-grey">
  <kaan-collections></kaan-collections>
</div>

<div id="kontakt" class="pt-50">
  <kaan-contact></kaan-contact>
</div>
