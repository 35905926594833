import { Injectable } from '@angular/core';
import { Headings1, Headings2, Image, Paragraphs, PrismicPageOptions, PrismicService, TypedDocument } from 'ngx-prismic';
import { Predicates } from 'prismic-javascript';
import { QueryOptions } from 'prismic-javascript/d.ts/ResolvedApi';
import { map } from 'rxjs/operators';

const DEFAULT_PAGE_OPTIONS: PrismicPageOptions = {
  page: 1,
  pageSize: 100
};

// Prismic object ids
const COLLECTION = 'collection';

@Injectable({
  providedIn: 'root'
})
export class CollectionService {

  constructor(private readonly prismicService: PrismicService) {
  }

  getCollections(pageOptions = DEFAULT_PAGE_OPTIONS): Promise<Collection[]> {
    const predicates = [
      Predicates.at('document.type', COLLECTION)
    ];

    const options: QueryOptions = {
      ...pageOptions
    };

    return this.prismicService.query<any>(predicates, options)
      .pipe(
        map(response => {
          return response.results
            .map(result => {
              return this.mapPrismicCollection(result);
            });
        })
      ).toPromise();
  }

  private mapPrismicCollection(prismicAuthor: TypedDocument<PrismicCollection>): Collection {
    return {
      id: prismicAuthor.uid,
      title: this.joinText(prismicAuthor.data.title),
      subtitle: this.joinText(prismicAuthor.data.subtitle),
      description: this.joinText(prismicAuthor.data.description),
      thumbnail: prismicAuthor.data.thumbnail.url,
      images: prismicAuthor.data.images
        .filter(image => image.image.url)
        .map(image => {
          return {
            image: image.image.url,
            thumb: image.thumbnail.url
          };
        })
    };
  }

  private joinText(section: { text: string }[]) {
    return section.map(item => item.text).join('<br>');
  }

}

export interface Collection {
  id: string;
  title: string;
  subtitle: string;
  description: string;
  thumbnail: string;
  images: ImagePair[];
}

export interface ImagePair {
  image: string;
  thumb: string;
}

export interface PrismicCollection {
  uid: string;
  title: Headings1;
  subtitle: Headings2;
  description: Paragraphs;
  thumbnail: Image;
  images: { image: Image, thumbnail: Image }[];
}
