<div class="image-box">
  <a class="image-item"
     [href]="service.href" [target]="service.target"
     fxLayout="column" fxLayoutAlign="center center">

    <div fxFlex fxLayout fxLayoutAlign="center center" class="px-20">
      <img src="../../../../assets/icons/{{service.icon}}">
    </div>

    <h2>{{service.title}}</h2>
    <p>{{service.subtitle}}</p>

  </a>
</div>
