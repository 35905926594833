import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { WindowRef } from '../../windowRef';
import { MobileMenuButtonComponent } from './mobile-menu-button/mobile-menu-button.component';

@Component({
  selector: 'kaan-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @ViewChild('fixedNavbar', { static: true }) fixedNavbar: ElementRef;
  @ViewChild('passingPoint', { static: true }) passingPoint: ElementRef;
  @ViewChild('mobileButton', { static: true }) mobileButton: MobileMenuButtonComponent;
  @ViewChild('mobileButton2', { static: true }) mobileButton2: MobileMenuButtonComponent;

  menuOpen = false;
  private animatedNavbar = true;

  constructor(
    private readonly windowRef: WindowRef,
    private renderer: Renderer2,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.subscribeToRoute();
  }

  private subscribeToRoute() {
    this.router.events.pipe(
      filter(e => e instanceof ActivationEnd)
    ).subscribe((e: ActivationEnd) => {
      if (!e.snapshot.data.animatedNavbar) {
        this.animatedNavbar = false;
        this.addShow();
      } else if (!this.animatedNavbar) {
        this.animatedNavbar = true;
        this.removeShow();
      }
    });
  }

  toggleMenu(): void {
    this.menuOpen ? this.closeMenu() : this.openMenu();
  }

  openMenu(): void {
    this.menuOpen = true;
    this.mobileButton.open();
    this.mobileButton2.open();
  }

  closeMenu(): void {
    this.menuOpen = false;
    this.mobileButton.close();
    this.mobileButton2.close();
  }

  @HostListener('window:resize', ['$event']) onResize() {
    this.closeMenu();
  }

  @HostListener('window:scroll', ['$event'])
  private onScroll(): void {
    if (!this.animatedNavbar) {
      return;
    }

    const topDistance = this.windowRef.nativeWindow.pageYOffset;
    const htmlElement = this.passingPoint.nativeElement as HTMLElement;

    if (topDistance >= htmlElement.offsetTop) {
      this.addShow();
    } else {
      this.removeShow();
    }
  }

  private addShow() {
    this.renderer.addClass(this.fixedNavbar.nativeElement, 'show');
  }

  private removeShow() {
    this.renderer.removeClass(this.fixedNavbar.nativeElement, 'show');
  }
}
