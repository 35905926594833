import { AgmCoreModule } from '@agm/core';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import 'hammerjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CollectionDetailsComponent } from './components/+collection-details/collection-details.component';
import { CookiesComponent } from './components/+cookies/cookies.component';
import { CollectionItemComponent } from './components/+home/collections/collection-item/collection-item.component';
import { CollectionsComponent } from './components/+home/collections/collections.component';
import { ContactItemComponent } from './components/+home/contact/contact-item/contact-item.component';
import { ContactComponent } from './components/+home/contact/contact.component';
import { HeaderComponent } from './components/+home/header/header.component';
import { HomeComponent } from './components/+home/home.component';
import { ServiceItemComponent } from './components/+home/services/service-item/service-item.component';
import { ServicesComponent } from './components/+home/services/services.component';
import { ImprintComponent } from './components/+imprint/imprint.component';
import { PrivacyComponent } from './components/+privacy/privacy.component';
import { FooterComponent } from './components/footer/footer.component';
import { MobileMenuButtonComponent } from './components/navbar/mobile-menu-button/mobile-menu-button.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { ScrollLockDirective } from './directives/scroll-lock.directive';
import { PrismicModule } from './modules/prismic.module';
import { WINDOW_PROVIDERS } from './windowRef';
import { ScullyLibModule } from '@scullyio/ng-lib';

const googleApiKey = 'AIzaSyCQiW6RQlft4qCVKDC7CG8bMI6B-WLwZB0';

const materialModules = [
  MatIconModule,
  MatButtonModule
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    FooterComponent,
    HeaderComponent,
    ServicesComponent,
    CollectionsComponent,
    ContactComponent,
    ServiceItemComponent,
    CollectionItemComponent,
    ContactItemComponent,
    MobileMenuButtonComponent,
    ScrollLockDirective,
    CollectionDetailsComponent,
    PrivacyComponent,
    ImprintComponent,
    CookiesComponent,
    PageHeaderComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'kaan-home-collection-webpage' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    ...materialModules,
    GalleryModule,
    LightboxModule,
    AgmCoreModule.forRoot({
      apiKey: googleApiKey
    }),
    PrismicModule,
    ScullyLibModule
  ],
  providers: [WINDOW_PROVIDERS],
  bootstrap: [AppComponent]
})
export class AppModule {
}
