import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kaan-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  marker = {
    lat: 50.080722,
    lng: 8.231594
  };

  mapCenter = {
    lat: 50.080722,
    lng: 8.231594
  };

  icon = {
    url: 'assets/icons/map-marker.svg',
    scaledSize: {
      width: 80,
      height: 110
    }
  };

  mapStyles = [
    {
      featureType: 'administrative',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#444444'
        }
      ]
    },
    {
      featureType: 'landscape',
      elementType: 'all',
      stylers: [
        {
          color: '#f2f2f2'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [
        {
          saturation: -100
        },
        {
          lightness: 45
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'all',
      stylers: [
        {
          visibility: 'simplified'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e2c742'
        }
      ]
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'transit',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    }
  ];

  services: Service[] = [
    {
      icon: 'phone.svg',
      title: 'Anrufen',
      subtitle: '+49 170 7172222',
      href: 'tel:+491707172222',
      target: '_self'
    },
    {
      icon: 'whatsapp.svg',
      title: 'WhatsApp',
      subtitle: '+49 170 7172222',
      href: 'https://wa.me/491707172222',
      target: '_blank'
    },
    {
      icon: 'mail.svg',
      title: 'Email',
      subtitle: 'info@werbeloewe.de',
      href: 'mailto:info@werbeloewe.de',
      target: '_self'
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}

export interface Service {
  icon: string;
  title: string;
  subtitle: string;
  href: string;
  target: string;
}
