import { Component, OnInit } from '@angular/core';
import { Collection, CollectionService } from '../../../services/collection.service';

@Component({
  selector: 'kaan-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss']
})
export class CollectionsComponent implements OnInit {

  collections?: Collection[];

  constructor(private collectionService: CollectionService) {
  }

  async ngOnInit() {
    this.collections = await this.collectionService.getCollections();
  }

}
