import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CollectionDetailsComponent } from './components/+collection-details/collection-details.component';
import { CookiesComponent } from './components/+cookies/cookies.component';
import { HomeComponent } from './components/+home/home.component';
import { ImprintComponent } from './components/+imprint/imprint.component';
import { PrivacyComponent } from './components/+privacy/privacy.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
    data: {
      animatedNavbar: true
    }
  },
  {
    path: 'kollektionen/:id',
    component: CollectionDetailsComponent
  },
  {
    path: 'impressum',
    component: ImprintComponent
  },
  {
    path: 'datenschutz',
    component: PrivacyComponent
  },
  {
    path: 'cookies',
    component: CookiesComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 60],
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
