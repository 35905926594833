import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PrismicClientModule, PrismicServiceConfig, PrismicServiceConfigProvider } from 'ngx-prismic';
import { environment } from '../../environments/environment';

const prismicConfig: PrismicServiceConfig = {
  prismicUrl: environment.prismic
};

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PrismicClientModule
  ],
  providers: [
    {
      provide: PrismicServiceConfigProvider,
      useValue: prismicConfig
    }
  ]
})
export class PrismicModule {
}
