<div class="kaan-container">
  <h1>Entdecken Sie<br>unsere Kollektionen</h1>
  <h2>...und überzeugen Sie sich selbst</h2>
  <p>
    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae
    ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
  </p>

  <div class="px-20" *ngFor="let collection of collections">
    <kaan-collection-item [collection]="collection"></kaan-collection-item>
  </div>

</div>
