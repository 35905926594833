import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Gallery, GalleryItem, ImageItem, ImageSize } from '@ngx-gallery/core';
import { Lightbox } from '@ngx-gallery/lightbox';
import { Collection, CollectionService } from '../../services/collection.service';

@Component({
  selector: 'kaan-collection-details',
  templateUrl: './collection-details.component.html',
  styleUrls: ['./collection-details.component.scss']
})
export class CollectionDetailsComponent implements OnInit {

  selectedCollection?: Collection;
  selectedId: string;

  galleryId = 'kaan-home-collection';

  constructor(
    private title: Title,
    private collectionService: CollectionService,
    private route: ActivatedRoute,
    private router: Router,
    public gallery: Gallery,
    private lightbox: Lightbox
  ) {
  }

  async ngOnInit() {
    this.route.params.subscribe(params => {
      this.selectedId = params['id']; // tslint:disable-line
    });

    const collections = await this.collectionService.getCollections();
    this.selectedCollection = collections.find(collection => collection.id === this.selectedId);

    if (!this.selectedCollection) {
      this.router.navigate(['/'], { fragment: 'kollektionen' });
    } else {
      this.title.setTitle(this.selectedCollection.title);
    }

    this.loadLightbox();
  }

  loadLightbox() {
    if (!this.selectedCollection) {
      return;
    }

    // Load items into gallery
    const items: GalleryItem[] = this.selectedCollection.images.map(
      image => new ImageItem({
        src: image.image,
        thumb: image.thumb
      }));

    const lightboxRef = this.gallery.ref(this.galleryId);

    // Add custom gallery config to the lightbox (optional)
    lightboxRef.setConfig({
      imageSize: ImageSize.Contain,
      thumb: false
    });

    // Load items into the lightbox gallery ref
    lightboxRef.load(items);
  }

  openLightBox(index: number) {
    this.lightbox.open(index, this.galleryId, { panelClass: 'fullscreen' });
  }

}
